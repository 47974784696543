/* styles.css */

.e-grid .e-row {
  margin: 12px; /* Adjust the margin value as needed */
}

.e-grid .e-toolbar .e-tbar-btn-text,
.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
}

.e-grid .e-toolbar .e-btn {
  background-color: transparent !important;
  border: none !important; /* Remove outline from toolbar buttons */
  color: #348bff !important; /* Button text color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button {
  color: #348bff !important;
  padding: 5px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
}

.custom-icon-button {
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
  color: #348bff !important; /* Icon color */
}

.custom-icon-button i {
  margin-right: 0; /* Remove margin around icons */
  color: #348bff !important; /* Icon color */
}

/* Target the Syncfusion Grid header cells */
.e-gridheader {
  color: #348bff !important; /* Set header text color */
  font-weight: bold !important;
}

/* Ensure the header cell text color is applied to the entire header */
.e-grid .e-headercell {
  color: #348bff !important; /* Set header text color */
  font-weight: bold !important;
}

.e-headertext {
  font-weight: bold !important;
}

/* Custom Dialog Height */
.custom-dialog .e-dialog.e-popup {
  max-height: 600px !important; /* Adjust height as needed */
  height: auto !important;
}

/* Custom Dialog Content Height */
.custom-dialog .e-dialog .e-dlg-content {
  max-height: 500px !important; /* Adjust if needed to fit within dialog */
  overflow-y: auto;
}

/* Set Toolbar Background to Transparent */
.e-toolbar {
  background-color: transparent !important; /* Toolbar background color */
  color: #fff !important; /* Toolbar text color for better contrast */
}

/* Hover Effect for Toolbar Buttons */
.e-toolbar .e-toolbar-items .e-toolbar-item:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ) !important; /* Light background on hover */
  border-color: #fff !important; /* Ensure border color stays white on hover */
}

/* Focus Effect for Toolbar Buttons */
.e-toolbar .e-toolbar-items .e-toolbar-item:focus {
  outline: none; /* Remove default outline */
}

.text-title {
  @apply text-left text-[#193861];
  font-size: 15px;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  background-color: transparent !important;
}

.e-icon-dlg-close {
  color: #fff;
}

.e-dlg-header-content {
  background-color: #348bff !important;
}

.e-dlg-header,
.e-dlg-header * {
  color: #fff !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background: transparent;
  border-color: transparent;
  color: #348bff !important;
}

.e-float-line {
  color: #348bff !important;
}

/* Change the font family for the entire grid */
.e-grid .e-headercell,
.e-grid .e-rowcell {
  font-family: "Inter", sans-serif;
}

/* Change the font color for the grid header cells */
.e-grid .e-headercell {
  color: #348bff; /* Change this to your desired color */
}

/* Change the font color for the grid row cells */
.e-grid .e-rowcell {
  color: #000000; /* Change this to your desired color */
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #e6f0ff;
  color: #0066ff;
  border: 2px solid #0066ff;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background-color: #d9e6ff;
}

.upload-button svg {
  width: 20px;
  height: 20px;
}

.z-0.w-full.flex-grow {
  height: 90vh !important;
  width: 100% !important;
}

#maps_control_1_tile_parent {
  height: 90vh !important;
  width: 100% !important;
}

/* Hide the arrows in Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.error-outline {
  border-color: red !important;
}

.user-type-dropdown {
  color: #fff !important;
  background: #3b82f6 !important;
}

.user-type-dropdown .e-float-input.e-input-group .e-input-group-icon,
.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
  color: white !important;
}

@media (max-width: 1024px) {
  .login-form {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .login-form {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .login-desc {
    display: none;
  }

  input {
    width: 100%;
  }
}

#grid_1712249876_2_dialogEdit_wrapper {
  max-height: 600px !important;
}

@media (max-width: 480px) {
  .login-form {
    width: 100%;
    padding: 10px;
  }

  input {
    width: 100%;
  }

  /* button {
    width: 100%;
  } */
}

.custom-grid .e-gridheader {
  display: none;
}

.custom-grid {
  border: none; /* Removes top border between content and header */
}